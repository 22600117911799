<template>
  <div id="app" v-loading="$store.state.api_loading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body, html {
  padding: 0;
  margin: 0;
}

.el-loading-mask {
  z-index: 9999 !important;
}

.table .cell {
  text-align: center;
  font-size: 12px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.table .table-pagination {
  margin-top: 20px;
  text-align: right;
}

.dialog-content table {
  border-collapse: collapse;
}

.dialog-content table th {
  /* background-color: #f5f2f0; */
  font-weight: 700;
  text-align: center;
  border: 1px solid #ccc;
  line-height: 1.5;
  min-width: 30px;
  padding: 3px 5px;
}
.dialog-content table td {
  border: 1px solid #ccc;
  line-height: 1.5;
  min-width: 30px;
  padding: 3px 5px;
  text-align: center;
}

.el-form-item__content .w-e-full-screen-container {
  z-index: 1;
  width: 1300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
