//标签页存储更新数据共用方法
// utils/tabHelper.js
import store from "@/store"; // 引入 Vuex store

const tabHelper = {
  /**
   * 更新指定标签页的数据
   * @param {String} tabId - 标签页的唯一标识
   * @param {Boolean} isSplicing - 标签页的唯一标识
   * @param {Object} data - 需要存储的数据(如搜索条件、页码等)
   */
  updateTabData(tabId, isSplicing, params) {
    tabId = this.getTabId(tabId);
    const data = {
      isSplicing: isSplicing,
      data: params,
    };
    store.dispatch("updateTabData", { tabId, data });
  },

  /**
   * 删除指定标签页的数据
   * @param {String} tabId - 标签页的唯一标识
   */
  removeTabData(tabId) {
    tabId = this.getTabId(tabId);
    store.dispatch("removeTabData", tabId);
  },

  /**
   * 获取指定标签页的数据
   * @param {String} tabId - 标签页的唯一标识
   * @returns {Object} - 返回标签页的数据(如搜索条件、页码等)，默认为空对象
   */
  getTabData(tabId) {
    tabId = this.getTabId(tabId);
    return store.state.tabs[tabId];
  },

  /**
   * 处理tabId 如果为拼接字符串 则截取
   */
  getTabId(tabId) {
    if (tabId) {
      const index = tabId.indexOf("?");
      return index !== -1 ? tabId.substring(0, index) : tabId;
    }
    return ""; // 如果 tabId 为空，则返回空字符串
  },

  /**
   *  判断对象是否为空
   * @param {*} params
   * @returns
   */
  isEmpty(params) {
    return Object.values(params).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0; // 数组为空
      }
      return value === ""; // 字符串为空
    });
  },
};

export default tabHelper;
