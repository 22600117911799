import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/layout/layout.vue";
import { EventBus } from "@/utils/eventBus";

Vue.use(VueRouter);

const routes = [
  {
    // 登陆
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login"),
  },
  {
    path: "/",
    redirect: "/login",
    component: layout,
    children: [
      {
        // 默认页面
        path: "/sys",
        name: "default",
        component: () => import("@/views/sys/default"),
      },
      {
        path: "/noAuth",
        name: "403",
        component: () => import("@/views/sys/403"),
      },
      {
        // 个人用户信息修改
        path: "/user/userInfo",
        name: "userInfo",
        component: () => import("@/views/user/infoChange"),
      },
      {
        // 系统菜单
        path: "/sys/menu",
        name: "menu",
        component: () => import("@/views/sys/menu"),
      },
      {
        // 系统部门
        path: "/sys/department",
        name: "department",
        component: () => import("@/views/sys/department"),
      },
      {
        // 角色信息
        path: "/sys/role",
        name: "role",
        component: () => import("@/views/sys/role"),
      },
      {
        // 管理员管理
        path: "/sys/user",
        name: "user",
        component: () => import("@/views/sys/user"),
      },
      {
        // 管理员登录记录
        path: "/sys/user/login",
        name: "userLogin",
        component: () => import("@/views/sys/userLogin"),
      },
      {
        // 管理员操作记录
        path: "/sys/user/operating",
        name: "userOperating",
        component: () => import("@/views/sys/userOperating"),
      },
      {
        // 创建直发委托单
        path: "/fourPx/create",
        name: "pxOrderCreate",
        component: () => import("@/views/4px/orderCreate"),
      },
      {
        // 查询直发委托单
        path: "/fourPx/get",
        name: "pxOrderList",
        component: () => import("@/views/4px/orderList"),
      },
      {
        // 取消直发委托单
        path: "/fourPx/cancel",
        name: "pxOrderCancel",
        component: () => import("@/views/4px/orderCancel"),
      },
      {
        // 获取标签
        path: "/fourPx/label/get",
        name: "pxLabelGet",
        component: () => import("@/views/4px/labelGet"),
      },
      {
        // 获取标签
        path: "/fourPx/label/list",
        name: "pxLabelGetList",
        component: () => import("@/views/4px/labelGetList"),
      },
      {
        // 物流产品查询
        path: "/fourPx/product/list",
        name: "pxProductList",
        component: () => import("@/views/4px/productList"),
      },
      {
        // 物流轨迹查询
        path: "/fourPx/update/weight",
        name: "updateWeight",
        component: () => import("@/views/4px/updateWeight"),
      },
      {
        // 物流轨迹查询
        path: "/fourPx/tracking/get",
        name: "pxTrackingList",
        component: () => import("@/views/4px/trackingList"),
      },
      {
        // 预估费用查询/运费试算
        path: "/fourPx/estimatedCost",
        name: "estimatedCost",
        component: () => import("@/views/4px/estimatedCost"),
      },
      {
        // 实时广告位管理
        path: "/sys/riom/ad",
        name: "riomAd",
        component: () => import("@/views/sys/ad"),
      },
      {
        // 帮助中心
        path: "/sys/help",
        name: "help",
        component: () => import("@/views/sys/help"),
      },
      {
        // 帮助中心
        path: "/sys/help/detail",
        name: "helpDetail",
        component: () => import("@/views/sys/helpDetail"),
      },
      {
        // 新闻博客
        path: "/sys/news",
        name: "news",
        component: () => import("@/views/sys/new"),
      },
      {
        // 订单评论管理
        path: "/sys/comment",
        name: "comment",
        component: () => import("@/views/sys/comment"),
      },
      {
        // 产品能力选项卡列表管理
        path: "/sys/tabs",
        name: "tabs",
        component: () => import("@/views/sys/tabs"),
      },
      {
        // 产品能力选项卡列表管理
        path: "/sys/slideshow",
        name: "slideshow",
        component: () => import("@/views/sys/slideshow"),
      },
      {
        // pcba估价配置
        path: "/sys/pcba/valuationPrice",
        name: "valuationPrice",
        component: () => import("@/views/order/valuationPrice"),
      },
      {
        // pcba估价配置
        path: "/sys/pcba/serviceCharge",
        name: "serviceCharge",
        component: () => import("@/views/order/serviceCharge"),
      },
      {
        // pcb价格配置
        path: "/sys/pcb/price/config",
        name: "pcbPriceConfig",
        component: () => import("@/views/order/pcbPriceConfig"),
      },
      {
        // 下单配置
        path: "/sys/pcb/config/dictionary",
        name: "pcbDictionaryConfig",
        component: () => import("@/views/sys/pcbDictionaryConfig"),
      },
      {
        // pcb特价配置
        path: "/sys/pcb/offers/config",
        name: "pcbOffers",
        component: () => import("@/views/sys/pcbOffers"),
      },
      {
        // pcb重量配置
        path: "/sys/pcb/weight/config",
        name: "pcbWeight",
        component: () => import("@/views/sys/pcbWeight"),
      },
      {
        // 钢网模具价格配置
        path: "/sys/stencil/price/config",
        name: "stencilPriceConfig",
        component: () => import("@/views/sys/stencilPriceConfig"),
      },
      {
        // 优惠券模板配置
        path: "/sys/discount/template",
        name: "discount",
        component: () => import("@/views/sys/discount"),
      },
      {
        // 快递公司配置
        path: "/sys/expressage/company",
        name: "expressageCompany",
        component: () => import("@/views/expressage/company"),
      },
      {
        // 快递国家配置
        path: "/sys/expressage/country",
        name: "expressageCountry",
        component: () => import("@/views/expressage/country"),
      },
      {
        // 快递费用配置
        path: "/sys/expressage/price",
        name: "expressagePrice",
        component: () => import("@/views/expressage/price"),
      },
      {
        // 银行卡信息
        path: "/sys/bank",
        name: "bank",
        component: () => import("@/views/sys/bank"),
      },
      {
        // 商城用户管理
        path: "/sys/user/info",
        name: "shopUser",
        component: () => import("@/views/user/info"),
      },
      {
        // 商城用户地址管理
        path: "/sys/shop/user/address",
        name: "address",
        component: () => import("@/views/user/address"),
      },
      {
        // 商城用户支付记录管理
        path: "/sys/shop/payment/record",
        name: "paymentRecord",
        component: () => import("@/views/user/paymentRecord"),
      },
      {
        // 用户优惠券使用情况
        path: "/sys/shop/discount",
        name: "shopDiscount",
        component: () => import("@/views/user/shopDiscount"),
      },
      {
        // 用户优惠券使用情况
        path: "/sys/transmit/record",
        name: "transmitRecord",
        component: () => import("@/views/user/transmitRecord"),
      },
      {
        // pcb商品管理
        path: "/sys/shop/cart/pcb",
        name: "shopCartPcb",
        component: () => import("@/views/shop/pcbCart"),
      },
      {
        // pcb商品管理
        path: "/sys/shop/cart/pcb/detail",
        name: "shopCartPcbDetail",
        component: () => import("@/views/shop/pcbCartDetail"),
      },
      {
        path: "/sys/shop/cart/pcb/gerber",
        name: "pcbGerberDetail",
        component: () => import("@/views/shop/pcbGerberDetail.vue"),
      },
      {
        // pcba商品管理
        path: "/sys/shop/cart/pcba",
        name: "shopCartPcba",
        component: () => import("@/views/shop/pcbaCart"),
      },
      {
        // pcba商品管理
        path: "/sys/shop/cart/pcba/bom",
        name: "shopCartPcbaBomDetail",
        component: () => import("@/views/shop/pcbaBomDetail"),
      },
      {
        // pcba商品管理
        path: "/sys/shop/cart/pcba/detail",
        name: "shopCartPcbaDetail",
        component: () => import("@/views/shop/pcbaCartDetail"),
      },
      {
        // 钢网商品管理
        path: "/sys/shop/cart/stencil",
        name: "shopCartStencil",
        component: () => import("@/views/shop/stencilCart"),
      },
      {
        // 钢网商品管理
        path: "/sys/shop/cart/stencil/gerber",
        name: "stencilGerberDetail",
        component: () => import("@/views/shop/stencilGerberDetail"),
      },
      {
        // /sys/proxy/order
        path: "/sys/proxy/order",
        name: "proxyOrder",
        component: () => import("@/views/shop/orderView"),
      },
      {
        // 订单管理
        path: "/sys/order/info",
        name: "orderInfo",
        component: () => import("@/views/order/info"),
      },
      {
        // 订单管理详情
        path: "/sys/order/info/detail",
        name: "orderInfoDetail",
        component: () => import("@/views/order/infoDetail"),
      },
      {
        // 订单补款管理
        path: "/sys/extra/price",
        name: "extraPrice",
        component: () => import("@/views/order/extra"),
      },
      {
        // pcb生产订单管理
        path: "/sys/order/pcb/purchase",
        name: "pcbOrder",
        component: () => import("@/views/order/pcb"),
      },
      {
        // pcba生产订单管理
        path: "/sys/order/pcba/purchase",
        name: "pcbaOrder",
        component: () => import("@/views/order/pcba"),
      },
      {
        // 钢网生产订单管理
        path: "/sys/order/stencil/purchase",
        name: "stencilOrder",
        component: () => import("@/views/order/stencil"),
      },
      {
        // 元器件采购单管理
        path: "/sys/order/pcba/subassembly",
        name: "subassembly",
        component: () => import("@/views/order/purchaseBom"),
      },
      {
        // 元器件采购单管理
        path: "/sys/order/pcba/subassembly/detail",
        name: "subassemblyDetail",
        component: () => import("@/views/order/purchaseBomDetail"),
      },
      {
        // 订单入库
        path: "/sys/order/entry",
        name: "entry",
        component: () => import("@/views/order/entry"),
      },
      {
        // 发货通知单
        path: "/sys/order/delivery/notice",
        name: "deliverNoticePending",
        component: () => import("@/views/order/deliverNoticePending"),
      },
      {
        // 发货记录
        path: "/sys/order/release/record",
        name: "orderDeliver",
        component: () => import("@/views/order/deliverList"),
      },
      {
        //每日流水
        path: "/sys/everyday/statement",
        name: "everyday",
        component: () => import("@/views/financial/daily"),
      },
      {
        //每月流水
        path: "/sys/monthly/statement",
        name: "monthly",
        component: () => import("@/views/financial/monthly"),
      },
      {
        //半月网站浏览量
        path: "/sys/website/visit",
        name: "websiteVisit",
        component: () => import("@/views/sys/websiteVisit"),
      },
      {
        // 论坛类目管理
        path: "/sys/forums/category",
        name: "forumsCategory",
        component: () => import("@/views/forums/category"),
      },
      {
        // 论坛文章标签管理
        path: "/sys/forums/tags",
        name: "forumsTags",
        component: () => import("@/views/forums/tags"),
      },
      {
        // 论坛文章管理
        path: "/sys/forums/article",
        name: "forumsArticle",
        component: () => import("@/views/forums/article"),
      },
      {
        // 论坛文章评论列表
        path: "/sys/forums/article/comment",
        name: "articleComment",
        component: () => import("@/views/forums/articleComment"),
      },
      {
        // 用户快速联系信息管理
        path: "/sys/letter",
        name: "letter",
        component: () => import("@/views/sys/letter"),
      },
      {
        // 用户快速联系信息管理
        path: "/sys/quick/contact",
        name: "quickContact",
        component: () => import("@/views/sys/quickContact"),
      },
      {
        // 公告管理
        path: "/sys/notice",
        name: "notice",
        component: () => import("@/views/sys/notice"),
      },
      {
        //商城商品类目管理
        path: "/sys/product/category",
        name: "productCategory",
        component: () => import("@/views/commodity/productCategory"),
      },
      {
        //商城商品管理
        path: "/sys/product",
        name: "products",
        component: () => import("@/views/commodity/products"),
      },
      {
        //商城商品规格评论管理
        path: "/sys/product/reviews",
        name: "productVariantReviews",
        component: () => import("@/views/commodity/productVariantReviews"),
      },
      {
        //商城商品详情
        path: "/sys/product/detail",
        name: "productDetail",
        component: () => import("@/views/commodity/productDetail"),
      },
      {
        //商城品牌管理
        path: "/sys/products/brand",
        name: "productBrand",
        component: () => import("@/views/commodity/productBrand"),
      },
      {
        //商品订单管理
        path: "/sys/order/product",
        name: "productsOrder",
        component: () => import("@/views/order/productsOrder"),
      },
      {
        //商品购物车管理
        path: "/sys/shop/cart/product",
        name: "productsCart",
        component: () => import("@/views/shop/productsCart"),
      },
      {
        //商品采购管理
        path: "/sys/product/purchase",
        name: "productsPurchase",
        component: () => import("@/views/order/productsPurchase"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.name) {
    setTimeout(() => {
      EventBus.$emit("route-change", to);
    });
  }
  next();
});

export default router;
