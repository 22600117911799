import Vue from "vue";
import Vuex from "vuex";
import login from "./modules/login";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api_loading: false,
    is_api_loading: true,

    username: "",
    email: "",
    userid: "",
    freshchatLoaded: false,

    // table标签页 存储搜索条件数据 以及页码数
    tabs: {},
  },
  mutations: {
    set_name(state, v) {
      state.username = v;
    },
    set_email(state, v) {
      state.email = v;
    },
    set_userid(state, v) {
      state.userid = v;
    },
    set_freshchatLoaded(state, v) {
      state.freshchatLoaded = v;
    },

    // table标签页 tabId 标签地址(唯一), isSplicing 是否拼接, data 参数
    setTabData(state, { tabId, data }) {
      Vue.set(state.tabs, tabId, data); // 设置某个标签页的数据
    },
    removeTabData(state, tabId) {
      Vue.delete(state.tabs, tabId); // 删除指定标签页的数据
    },
  },
  actions: {
    set_name({ commit }, v) {
      commit("set_name", v);
    },
    set_email({ commit }, v) {
      commit("set_email", v);
    },
    set_userid({ commit }, v) {
      commit("set_userid", v);
    },
    set_freshchatLoaded({ commit }, v) {
      commit("set_freshchatLoaded", v);
    },

    // table标签页 tabId 标签地址(唯一),  data 参数
    updateTabData({ commit }, { tabId, data }) {
      commit("setTabData", { tabId, data });
    },
    removeTabData({ commit }, tabId) {
      // 执行删除逻辑，比如调用 mutation
      commit("removeTabData", tabId);
    },
  },
  getters: {},
  modules: {
    login,
  },
});
