<template>
  <div class="layout">
    <el-container>
      <el-aside width="auto">
        <Aside />
      </el-aside>

      <el-container>
        <el-header>
          <Header />
        </el-header>

        <el-main>
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

  export default {
    components: {
      Aside: () => import("@/components/aside.vue"),
      Header: () => import("@/components/header.vue"),
    },
  }

</script>

<style lang="less" scoped>
  .el-header {
    padding: 0;
  }
  .el-main {
    height: calc(100vh - 60px);
    padding: 0 15px;
    background: #071f34;
    overflow: auto;
  }
  /*滚动条样式*/
  .el-main::-webkit-scrollbar {
    width: 5px;
  }
  .el-main::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #dcb885;
  }
  .el-main::-webkit-scrollbar-track {
    border-radius: 0;
    background: no-repeat;
  }
  /* over */
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>