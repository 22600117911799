
function downloadALiFile(url,fileName) {
  try {
    var x = new XMLHttpRequest();
    x.open("GET",url,true);
    x.responseType = 'blob';
    x.onload=function(e){
    var url = window.URL.createObjectURL(x.response);
    var a= document.createElement('a');
    a.href= url;
    a.target = '_blank';
    a.download = fileName;
    a.click();
    a.remove()
  }
    x.send();
  } catch (error) {
    
  }
}

export const downloadALi = downloadALiFile;