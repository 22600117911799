import { Message } from 'element-ui';

let messageInstance = null;

const ismessage = (options) => {
    if (messageInstance) {
        messageInstance.close()
    }
    messageInstance = Message(options);
};

['error', 'success', 'info', 'warning'].forEach(type => {
    ismessage[type] = options => {
        if (typeof options == 'string') {
            options = {
                message: options
            }
        }
        options.type = type;
        return ismessage(options);
    }
})

export const message = ismessage;